:root{
  --main-color: #247b6aff;
  --stufe1-color: rgba(36, 123, 106, 0.276);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wave {
  position: relative;
  top: -120px;
  z-index: 0!important;
 
}

.znav{
  z-index: 1000!important;
}

.commentSection{
  z-index: -2!important;
  position: relative;
  top: -650px;
  .textsize{
    font-size:  1.3rem;
   }
   .textsize2{
    font-size: 0.8rem;
   }
}

.hiddeni{
opacity: 0;
filter: blur(5px);
transition: all 1s;
/*transform: translateX(100%);*/
}
.showi{
opacity: 1;
filter: blur(0);
/*transform: translateX(0);*/
}
@media (prefers-reduced-motion: no-preference) {
  .hiddeni {
    transition: all 1s;
  }
}
.card:nth-child(2){

transition-delay: 200ms;
}
.card:nth-child(3){
  transition-delay: 400ms;
}
.card:nth-child(4){
  transition-delay: 600ms;
}
.rbc-btn {
  background-color: #247b6aff!important;
  color: #38dc1e!important;
}
.rbc-month-header {
  background-color: var(--main-color)!important;
  color: #f3f0f0!important;
  
}
/* Grundlegende Flexbox-Einstellungen */
.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Abstand zwischen den Bildern */
}

.image-grid div {
  flex: 1 1 200px; /* Bilder flexibel, aber nicht kleiner als 200px */
}

.image-grid img {
  width: 100%;
  height: auto;
}
.social-media-links .icon {
  margin-top: 10%;
  margin-right: 20px; /* Abstand zwischen Icons */
  color: #ffffff; /* Icon-Farbe */
  font-size: 24px; /* Icon-Größe */
}

/* Farben für Icons anpassen */
.footer{
  position:  absolute;
  width: 100%;
}
.sold-out-banner {
  z-index: 1;
  overflow: hidden;
  position: absolute;
  top: 150px; /* Oder an jeder anderen Position, die du bevorzugst */
  right: -0px;
  background-color: rgba(191, 8, 8, 0.738); /* Oder jede andere Farbe, die du für den Banner verwenden möchtest */
  color: white; /* Textfarbe */
  padding: 5px 100px; /* Anpassen für die Größe des Banners */
  transform: rotate(45deg); /* Schräg stellen */
  transform-origin: right top; /* Drehpunkt anpassen, damit der Text richtig ausgerichtet ist */
  text-align: center;
  font-weight: bold;
  font-size: 14px; /* Schriftgröße anpassen */
  white-space: nowrap; /* Verhindert, dass der Text umbricht */
}
.grayscale {
  filter: grayscale(100%);
}
.cookie{
  z-index: 100000!important;
}
