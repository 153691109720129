:root{
  --main-color: #247b6aff;
  --stufe1-color: rgba(36, 123, 106, 0.276);
}


  .flipper{
    position: relative;
    top:-800px;
    background: var(--main-color);
    text-align: center;
    padding-bottom: 0.6%;
    .headline{
      padding:1%;
    }
  }
  .cardflipper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 50vh;
    
  }
  
  .container {
    width: 1000px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  
  .container .card {
    position: relative;
    height: 200px;
    width: 200px;
    margin: 0.6%;
  }
  
  .container .card .face {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: .5s;
    transition: .5s;
  }
  
  .container .card .face.face1 {
    background: #333333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
            
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 1;
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  
  .container .card .face.face1 img {
    object-fit: cover;
    width: 180px;
    height: 140px;
  }
  
  .container .card .face.face1 h3 {
    margin: 1% 0 0;
    color: #fff;
    text-align: center;
    font-size: 1.2em;
    font-weight: 700;
  }
  
  .container .card:hover .face.face1 {
    -webkit-transform: translateY(-100%) rotateX(90deg);
            transform: translateY(-100%) rotateX(90deg);
    background: #7dfff0;
  }
  
  .container .card .face.face2 {
    background: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 1%;
    font-size: 0.9em;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: translateY(100%) rotateX(90deg);
            transform: translateY(100%) rotateX(90deg);
  }
  
  .container .card:hover .face.face2 {
    -webkit-transform: translateY(0) rotateX(0deg);
            transform: translateY(0) rotateX(0deg);
  }


  /* wave */
  
  .expander {
    position: relative;
    top:-680px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    
   
  }
  @media only screen and (max-width: 2900px) and (min-width: 1700px){
    .expander {
      top:-780px;
    }
  }
  .containerexpander {
    position: relative;
    
    width: 2000px;
    
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        
  }
  
  .containerexpander .card {
    height: 350px;
    position: relative;
    padding: 1%;
  }
  
  .containerexpander .card .face {
    width: 300px;
    height: 300px;
    -webkit-transition: all .5s;
    transition: all .5s;
  }
  
  .containerexpander .card .face.face1 {
    position: relative;
    background: #333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 1;
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
  }
  
  .containerexpander .card .face.face1 .content {
    
    -webkit-transition: .5s;
    transition: .5s;
  }
  
  .containerexpander .card .face.face1 .content img {
    width: 250px;
    max-height: 200px;
    object-fit: cover;
  }
  
  .containerexpander .card .face.face1 .content h3 {
    margin: 10px 0 0;
    padding: 0;
    color: #fff;
    text-align: center;
    font-size: 1.5em;
  }
  
  .containerexpander .card .face.face2 {
    position: relative;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.8);
            box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.8);
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  
  .containerexpander .card .face.face2 .content p {
    margin: 0;
    padding: 0;
  }
  
  .containerexpander .card .face.face2 .content a {
    margin: 20px 0 0;
    display: inline-block;
    color: #333;
    text-decoration: none;
    padding: 5px;
    border: 1px solid #333;
  }
  
  .containerexpander .card .face.face2 .content a:hover {
    background: #333;
    color: #fff;
  }
  
  .containerexpander .card:hover .face.face1 {
    background: #05ab6b;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  
  .containerexpander .card:hover .face.face1 .content {
  
  }
  
  .containerexpander .card:hover .face.face2 {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  @media only screen and (max-width: 2900px) and (min-width: 1700px){
    .titlepic{
  
      min-height: 700px;
    }
    .expander {
      top:-830px;
    }
    .wave{
      top:-140px;
    }
  }
  @media only screen and (max-width: 1700px) and (min-width: 1400px){
    .titlepic{
  
      min-height: 600px;
    }
  }
  @media only screen and (max-width: 1400px) and (min-width: 1100px){
    .titlepic{
  
      min-height: 500px;
    }
  }
  @media only screen and (max-width: 1100px) and (min-width: 700px){
    .commentSection{
      top: -650px;
     
    }
    .titlepic{
      min-height: 500px;
    }
   
      .expander {
        top:-480px;
      }
    
    .containerexpander {  
      width: 2000px;
    }
    
    .containerexpander .card {
      height: 350px;
      padding: 1%;
    }
    
    .containerexpander .card .face {
      width: 200px;
      height: 200px;
    }
    
    .containerexpander .card .face.face1 {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
    }
    
    .containerexpander .card .face.face1 .content img {
      width: 150px;
      max-height: 100px;
    }
    
    .containerexpander .card .face.face1 .content h3 {
      margin: 10px 0 0;
      font-size: 1em;
    }
    
    .containerexpander .card .face.face2 {
      font-size: 0.8em;
      padding: 10px;
      -webkit-box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.8);
              box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.8);
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
    
    .containerexpander .card .face.face2 .content a {
      margin: 10px 0 0;
      font-size: small;
      padding: 2px;
      border: 1px solid #333;
    }
  }
  @media only screen and (max-width: 900px) and (min-width: 700px){
    .titlepic{
  
      min-height: 500px;
    }
    
  }
  @media only screen and (max-width: 700px) and (min-width: 500px){
    .flipper{
      top: -550px;
    }
    .backTopicPart {
      top: -550px!important;
    }
    .swiperBack{
      top: -550px !important;;
    }
    .commentSection{
      top: -400px;
     
      
    }
    .titlepic{
  
      min-height: 350px;
    }
    .wave{
      top: -70px;
    }
   
      .expander {
        top:-290px;
      }
    
    .containerexpander {  
      width: 2000px;
    }
    
    .containerexpander .card {
      height: 150px;
      padding: 1%;
    }
    
    .containerexpander .card .face {
      width: 140px;
      height: 140px;
    }
    
    .containerexpander .card .face.face1 {
      -webkit-transform: translateY(70px);
              transform: translateY(70px);
    }
    
    .containerexpander .card .face.face1 .content img {
      width: 120px;
      max-height: 90px;
    }
    
    .containerexpander .card .face.face1 .content h3 {
      margin: 10px 0 0;
      font-size: small;
    }
    
    .containerexpander .card .face.face2 {
      font-size: 0.5em;
      padding: 10px;
      -webkit-box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.8);
              box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.8);
      -webkit-transform: translateY(-70px);
              transform: translateY(-70px);
    }
    
    .containerexpander .card .face.face2 .content a {
      margin: 10px 0 0;
      font-size: x-small;
      padding: 2px;
      border: 1px solid #333;
    }
  }
  @media only screen and (max-width: 290px) and (min-width: 0px){
    .commentSection{
      top: -250px;
    }
    .text1{
      font-size:small;
    }
    .titlepic{
  
      max-height: 150px;
    }
    .wave{
      top: -38px;
    }
   
      .expander {
        top:-200px;
      }
    
    .containerexpander {  
      width: 2000px;
    }
    
    .containerexpander .card {
      height: 200px;
      padding: 1%;
    }
    
    .containerexpander .card .face {
      width: 80px;
      height: 200px;
    }
    
    .containerexpander .card .face.face1 {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
    }
    
    .containerexpander .card .face.face1 .content img {
      width: 80px;
      max-height: 160px;
    }
    
    .containerexpander .card .face.face1 .content h3 {
      margin: 10px 0 0;
      font-size: small;
    }
    
    .containerexpander .card .face.face2 {
      font-size: 0.45em;
      padding: 10px;
      -webkit-box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.8);
              box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.8);
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
    
    .containerexpander .card .face.face2 .content a {
      margin: 10px 0 0;
      font-size: x-small;
      padding: 2px;
      border: 1px solid #333;
    }
  }

  @media only screen and (max-width: 500px) and (min-width: 290px){
    .flipper{
      top:-400px;
    }
    .backTopicPart {
      top: -400px!important;
    }
    .swiperBack{
      top: -400px !important;;
    }
    .container .card {
      height: 180px;
      width: 180px;
    }
    
    .container .card .face.face1 img {
      width: 160px;
      height: 120px;
    }
    
    .container .card .face.face1 h3 {
      font-size: 1em;
      font-weight: 700;
    }
    
    .container .card .face.face2 { 
      font-size: 0.7em;
    }


    .commentSection{
      top: -250px;
      .textsize{
        font-size:  0.8rem;
        line-height: 170%;
       }
       .textsize2{
        font-size: 0.8rem;
       }
      }
    .titlepic{
  
      max-height: 250px;
    }
    .wave{
      top: -30px;
    }
   
      .expander {
        top:-190px;
      }
    
    .containerexpander {  
      width: 2000px;
    }
    
    .containerexpander .card {
      height: 180px;
      padding: 1%;
    }
    
    .containerexpander .card .face {
      width: 110px;
      height: 180px;
    }
    
    .containerexpander .card .face.face1 {
      -webkit-transform: translateY(90px);
              transform: translateY(90px);
    }
    
    .containerexpander .card .face.face1 .content img {
      width: 100px;
      max-height: 120px;
    }
    
    .containerexpander .card .face.face1 .content h3 {
      margin: 10px 0 0;
      font-size: small;
    }
    
    .containerexpander .card .face.face2 {
      font-size: 0.5em;
      padding: 10px;
      -webkit-box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.8);
              box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.8);
      -webkit-transform: translateY(-90px);
              transform: translateY(-90px);
    }
    
    .containerexpander .card .face.face2 .content a {
      margin: 10px 0 0;
      font-size: x-small;
      padding: 2px;
      border: 1px solid #333;
    }
  }


  /*swiper*/

  .swiperBack{
    position: relative;
    top: -799px;
    background-color: var(--stufe1-color);
  }
  .swiperUni{
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: var(--stufe1-color);
  }

  .swiper {
    width: 100%;
    height: 100%;
     }
  .swiper-slide {
    text-align: center;
    font-size: 1rem;
    
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper {
    width: 100%;
    height: 400px;
    margin: 20px auto;
  }
  .swiper-button-prev{
color:  var(--main-color)!important;
  }
  .swiper-button-next{
    color:  var(--main-color)!important;
  }
  .append-buttons {
    text-align: center;
    margin-top: 20px;
  }
  
  .append-buttons button {
    display: inline-block;
    cursor: pointer;
    border: 1px solid  var(--main-color);
    color:  var(--main-color);
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    margin: 0 10px;
    font-size: 13px;
  }

  /*sildes*/
  .slide {
   
    margin: 1%;
    position: relative;
    background-color: #333;
  }

  .slide:hover{
    box-shadow: 0 0 5px  var(--main-color);
  }
  
  .title-image {
    position: relative;
    height: 350px; /* Passen Sie die Höhe nach Bedarf an */
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
   
  }
  
  .author-image {
    max-width: 50px; /* Passen Sie die Größe nach Bedarf an */
    max-height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 20px  var(--main-color);
    object-fit: cover;
    position: absolute; /* Overlay über dem Titelbild */
    top: 12px; /* Abstand von oben */
    right: 12px; /* Abstand von rechts */

  }
  .author-image-details {
    position: relative;
    width: 50px; /* Passen Sie die Größe nach Bedarf an */
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 5px  var(--main-color);
    object-fit: cover;
    

  }
  
  .title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: white; /* Stellt weiße Textfarbe für bessere Lesbarkeit sicher */
    padding: 2%; /* Fügt etwas Polsterung hinzu, um den Text vom Rand zu entfernen */
    padding-bottom: 10%;
    text-align: center; /* Zentriert den Titel */
    font-size: 1.2rem; /* Passt die Schriftgröße an, falls nötig */
  }
  .calendarDetails {
    z-index: 2;
    box-shadow: 0 0 20px  var(--main-color)1;
    min-width: 50px;
    position: absolute;
    top: 70px;
    left: 50px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  .calendar {
    z-index: 2;
    box-shadow: 0 0 20px  var(--main-color)1;
    min-width: 50px;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .calendar-month {
    background-color:  var(--main-color);
    color: white;
    padding: 2px 5px;
    text-align: center;
    font-weight: bold;
    font-size: 0.8rem;
  }
  
  .calendar-day {
    background-color: white;
    padding: 5px;
    font-weight: bold;
    text-align: center;
    font-size: 1.2rem;
  }
  
  .swiper-pagination-bullet-active{
    background-color:  var(--main-color)!important;
  }

  .backTopicPart {
    position: relative; /* Stellt sicher, dass der Hintergrund absolut positioniert wird */
    top: -800px;
  }
  
  .bg-blur-image {
    position: absolute;
    inset: 0; /* Füllt den Eltern-Container vollständig aus */
    background-size: cover;
    background-position: center;
    filter: blur(10px); /* Wenden Sie den Blur-Filter an */
    z-index: -2; /* Stellt den Hintergrund hinter den Inhalt */
  }
  .bg-gradient{
    inset: -1; 
    background: rgba(0, 0, 0, 0.63);
    z-index: -1;
  }
  [type=checkbox]:checked, [type=radio]:checked {
    background-color: var(--main-color)!important;
  }